import { StatusCode } from "@/core/api/enums/status_code"
import { RESTSettings } from "@/core/api/client/rest_settings"

export class RESTClient {
  private _settings: RESTSettings

  constructor(settings: RESTSettings) {
    this._settings = settings
  }

  private _sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  private async _fetch(
    token: string,
    url: string,
    options: RequestInit = {},
  ): Promise<Response> {
    let attempt: number = 0
    let lastError: any
    if (!url.startsWith("/")) url = "/" + url
    url = `${this._settings.domainUrl}${url}`

    if (typeof options?.body === "string") {
      options.headers = {
        ...options.headers,
        "Content-Type": "application/json",
      }
    }

    let headers = {
      ...options?.headers,
      Authorization: `Bearer ${token}`,
    }

    options = {
      ...options,
      headers: { ...headers },
    }

    while (attempt < this._settings.numRetries) {
      try {
        const response: Response = await fetch(url, options)
        if (response.ok) {
          return response
        } else {
          throw new Error(`RESTClient - HTTP error! status: ${response.status}`)
        }
      } catch (error) {
        lastError = error
        attempt++

        if (attempt < this._settings.numRetries) {
          const delayTime: number = this._settings.backoff * 2 ** (attempt - 1)
          console.warn(
            `RESTClient - Attempt ${attempt} failed. Retrying in ${delayTime}ms...`,
          )
          await this._sleep(delayTime)
        } else {
          console.error(
            `RESTClient - All ${this._settings.numRetries} attempts failed.`,
          )
          throw lastError
        }
      }
    }

    throw lastError
  }

  public async fetchWithRetry(
    token: string,
    url: string,
    options: RequestInit = {},
  ): Promise<any> {
    try {
      const response: Response = await this._fetch(token, url, options)
      const contentType: string | null = response.headers.get("content-type")
      if (response.status !== StatusCode.NoContent) {
        if (
          contentType &&
          contentType.toLowerCase().indexOf("application/json") !== -1
        ) {
          return await response.json()
        } else {
          return await response.text()
        }
      }

      return response
    } catch (error) {
      const requestUrl: string = `${this._settings.domainUrl}${url}`
      console.error(`RESTClient - Url: ${requestUrl}, Token: ${token}`, {
        options: options,
        error,
      })
      return null
    }
  }
}
