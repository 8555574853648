import swr, { useSWRConfig as swrConfig } from "swr"
import { getItem } from "../browserStorage"
import { FetchClient } from "@/core/client/fetch_client"
import { getService } from "hooks/useStartup"

export const useSWRConfig = swrConfig

export const fetcher = async (
  url: string,
  options?: RequestInit,
): Promise<any> => {
  const client: FetchClient = getService(FetchClient)
  const response = await client.fetchAsync(url, options)
  return response
}
/*
export const fetcher = (url: string, options?: RequestInit) => {
  if (!url.startsWith("http")) {
    const apiDomain = process.env["NEXT_PUBLIC_API_URL"]

    if (!url.startsWith("/")) url = "/" + url

    url = `${apiDomain}${url}`
  }

  if (typeof options?.body === "string") {
    options.headers = {
      ...options.heade<<rs,
      "Content-Type": "application/json",
    }
  }

  let headers = {
    ...options?.headers,
    Authorization: `Bearer ${getItem("_ft_a_")}`,
  }

  options = {
    ...options,
    headers: { ...headers },
  }

  return fetch(url, options)
    .then((res) => {
      const contentType = res.headers.get("content-type")

      if (res.status !== 204) {
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return res?.json()
        } else {
          return res.text()
        }
      }
      return res
    })
    .then((res) => {
      if (res.error) {
        throw res.message
      }
      return res
    })
}
*/
export const getToken = (): string => {
  const token: string = getItem("_ft_a_")
  return token
}

export const useSWR = (uri: string, options = {}) => swr(uri, fetcher, options)
