import { RESTSettings } from "@/core/api/client/rest_settings"
import { injectable } from "tsyringe"

@injectable()
export class FetchSettings extends RESTSettings {
  constructor() {
    const apiUrl: string = process.env["NEXT_PUBLIC_API_URL"]
    const numRetries: number = 3
    const backoff: number = 300
    super(apiUrl, numRetries, backoff)
  }
}
