import { RESTClient } from "@/core/api/client/rest_client"
import { injectable } from "tsyringe"
import { FetchSettings } from "./fetch_settings"
import { getToken } from "@/utils/api"

@injectable()
export class FetchClient extends RESTClient {
  constructor(settings: FetchSettings) {
    super(settings)
  }

  public async fetchAsync(
    url: string,
    options: RequestInit = {},
  ): Promise<any> {
    const token: string = getToken()
    return await this.fetchWithRetry(token, url, options)
  }
}
